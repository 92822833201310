'use strict';
const core = require('core/cart/cart');
var scrollAnimate = require('core/components/scrollAnimate');

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
core.updateCartTotals = function (data) {
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
    $('.shipping-cost span').empty().append(data.totals.totalShippingCost);
    $('.grand-total span, .checkout-continue .checkout-btn-grand-total').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $('.checkout-continue .checkout-btn').attr('data-price', data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $('.sub-total span').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if ('shippingProcessingTimeText' in data.totals) {
        $('.shipping-processing-time').text(data.totals.shippingProcessingTimeText);
    }

    if (data.totals.totalTaxValue === 0 || data.totals.totalTaxValue === '-') {
        $('.tax-total span').empty().append(data.resources.taxCalculatedAtCheckout);
    } else {
        $('.tax-total span').empty().append(data.totals.totalTax);
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total span').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $('.giftcertificate-discount').removeClass('d-none');
        $('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $('.giftcertificate-discount-total').text('- ' + data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $('.giftcertificate-discount').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        $('.cartAdditionalPaymentButtons').removeClass('d-none');
    } else {
        $('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    data.items.forEach(function (item) {
        var itemPrice;
        var itemTotalPrice;

        if (item.productType === 'giftCertificate') {
            itemPrice = item.priceTotal.price;
            itemTotalPrice = item.priceTotal.price;
        } else {
            itemPrice = item.renderedPrice;
            itemTotalPrice = item.priceTotal.renderedPrice;
        }

        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(itemPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(itemPrice);
        $('.item-total-' + item.UUID).empty().append(itemTotalPrice);
    });
}

core.init = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        module.exports.confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    $('.uuid-' + uuid).closest('.card').remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);
                }

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var isMiniCart = $stepper.closest('.minicart').length > 0;
        var selectId = $stepper.closest('.quantity-form').find('select').attr('id');
        var $select = $('.cart-page select#' + selectId);
        var value = parseInt($stepper.find('input').val());

        // if the qty change was triggered from the minicart, manually update cart qty input values
        if (isMiniCart) {
            var $cartStepper = $select.next('.quantity-stepper');
            $cartStepper.find('input').prop('value', value).prop('data-qty', value);
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);

                if (data.bonusButtonHtml && $(this).parents('.card').find('.bonus-product-button').length === 0) {
                    $(this).parents('.card').children('.card-body').children('.row').last().after(data.bonusButtonHtml);
                }

                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                } else if ($('.bonus-product-button:hidden').length >= 0 && $('.card-bonus-product-section').length === 0) {

                    $('.bonus-product-button:hidden').click();
                }

            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;

        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                    $('.coupon-code-field').trigger('focus');
                    $.spinner().stop();
                } else {
                    // If cart item was added/removed via promo code submit
                    if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {

                        // Clean Url Structure of any erroneous parameters
                        if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                            history.replaceState({}, null, data.actionUrls.showUrl);
                        }
                        // Force uncached reload
                        window.location.reload(true);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        module.exports.updateCartTotals(data);
                        module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                        module.exports.validateBasket(data);
                        $('body').trigger('promotion:success', data);
                        $('.coupon-code-field').val('');
                        $.spinner().stop();
                    }
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();

                // If cart item was added/removed via promo code removal
                if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {
                    // Clean Url Structure of any erroneous parameters
                    if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                        history.replaceState({}, null, data.actionUrls.showUrl);
                    }
                    // Force uncached reload
                    window.location.reload(true);
                } else {
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        var $parent = $('.bonus-product-button').parents('.card-body');
        $.spinner().start();

        if ($('.js-embeded-bonus-line-item').length > 0) {

            var arrayofBonusProductsLineItemsUUID = $('.js-embeded-bonus-line-item').map(function () {
                return this.value;
            }).toArray();
            var changeBonusURL = $(this).data('url');
            var deleteBonusURL = $(this).data('removeurl');
            var form = { id: JSON.stringify(arrayofBonusProductsLineItemsUUID) };

            var promise = new Promise(function (resolve, reject) {
                $.ajax({
                    url: deleteBonusURL,
                    method: 'POST',
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        //clear already printed bonus products
                        $('.js-embeded-bonus-line-item-container').remove();

                        resolve(data);
                    },
                    error: function (err) {
                        module.exports.createErrorNotification(err.msgError);
                        $.spinner().stop();
                    }
                });

            });
            showAndHideIFrameCover();
            promise.then(function (data) {
                $.ajax({
                    url: changeBonusURL,
                    method: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        $('body').trigger('cart:bonusproduct:edit', { data: data, parent: $parent });
                        showAndHideIFrameCover();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }, function () {
                $.spinner().stop();
            });
        } else {
            $.spinner().start();
            $.ajax({
                url: $(this).data('url'),
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('cart:bonusproduct:edit', { data: data, parent: $parent });
                    showAndHideIFrameCover();
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
        showAndHideIFrameCover();
    });

    function updateIFrameCoverSize() {
        updateCoverSize('#paypal-express-method .paypal-button-container-cart1', '.paypal-cover-div');
        updateCoverSize('.js-paymentmethodwarning-affectedcontainer:has(.dw-apple-pay-button)', '.apple-pay-cover-div');
    }
    
    function updateCoverSize(parentSelector, coverClass) {
        let $parentElement = $(parentSelector);
    
        let parentWidth = $parentElement.outerWidth();
        let parentHeight = $parentElement.outerHeight();
    
        // Update the width and height of elements with the specified cover class
        $(coverClass).width(parentWidth).height(parentHeight);
    }

    $(document).ready(function () {
        // Select the parent element using the ID and class
        let $parentElementPayPal = $('#paypal-express-method .paypal-button-container-cart1');

        // Get the dimensions of the parent element
        let parentWidthPayPal = $parentElementPayPal.outerWidth();
        let parentHeightPayPal = $parentElementPayPal.outerHeight();

        // Create a new element (div) with the class 'paypal-cover-div' and set its dimensions and styling
        let paypalCoverDiv = $('<div class="paypal-cover-div"></div>').css({
            'width': parentWidthPayPal + 'px',
            'height': parentHeightPayPal + 'px',
            'z-index': 150,
            'cursor': 'pointer',
            'position': 'absolute',
            'min-height': '45px',
            'background-color': 'rgb(0 0 0 / 0%)'
        });

        // Insert the new element into the parent element at the beginning (as the first child)
        $($parentElementPayPal).prepend(paypalCoverDiv);
    });

    $('body').on('click', '.bonus-product-button', function (e) {
        showAndHideIFrameCover();
    });

    $('body').on('click', '#without-bonus', function (e) {
        let $paypalElement = $('.paypal-cover-div');
        let $applepayElement = $('.apple-pay-cover-div');

        if ($('#without-bonus').is(":checked")) {
            $paypalElement.hide();
            $applepayElement.hide();
        } else {
            $paypalElement.show();
            $applepayElement.show();
        }
    });

    /**
     * Event Handling Function:
     * Description: This jQuery function is triggered when a click event occurs on an element
     * with the class 'paypal-cover-div' or 'apple-pay-cover-div' within the 'body'. It performs the following tasks:
     * 1. Hides a cart error message with the class 'js-cart-error-message'.
     * 2. Checks if a specific condition, determined by the function 'checkIfBonusProductNotSelected()', is met.
     * 3. If the condition is true, prevents the default click behavior, stops event propagation,
     * shows the cart error message, and animates scrolling to the 'card-bonus-product-section'.
     * 
     * This code is commonly used to handle click events on designated elements,
     * implementing specific actions based on certain conditions, such as displaying error messages or triggering animations.
     * The elements with the classes 'paypal-cover-div' and 'apple-pay-cover-div' are expected to be clickable and are associated
     * with interactions related to the cart and bonus products. If a bonus product is not selected, the function prevents
     * the default action, stops event propagation, displays an error message, and scrolls to the bonus product section.
     */
    $('body').on('click', '.paypal-cover-div, .apple-pay-cover-div', function (e) {
        // Select the cart error message element
        let $errorMsg = $('.js-cart-error-message');

        // Hide the cart error message
        $errorMsg.hide();

        // Check if a bonus product is not selected
        if (checkIfBonusProductNotSelected()) {
            // Prevent default click behavior and stop event propagation
            e.preventDefault();
            e.stopPropagation();

            // Show the cart error message and animate scrolling to the bonus product section
            $errorMsg.show();
            scrollAnimate($('.card-bonus-product-section'));
        }
    });

    $('body').on('click', '.cart-page .js-cart-checkout-btn, .dw-apple-pay-button', function (e) {
        $('.js-cart-error-message').hide();

        if ($('.js-choose-bonus-product-cart:visible').length && !$('#without-bonus').is(":checked")) {
            e.preventDefault();
            e.stopPropagation();

            var $errorMsg = $('.js-cart-error-message');

            $errorMsg.show();
            scrollAnimate($('.card-bonus-product-section'));
            $(e.target).css('background-color', '#532e63');
        }
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });


    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        // Get Product Options from edit modal and go through each to verify if valid before Updating cart.
        var productOptions = $('.product-option');
        var optionError = false;
        var firstErroredOption = null; //Used for scrolling to the errored section on mobile

        productOptions.each(function () {
            var productOption = $(this);
            if (productOption.data('option-id') == 'engrave') {
                var engraveMessage = productOption.find('input[name=engravable_message]');
                var engraveMessageValueEmpty = engraveMessage.length > 0 && (engraveMessage.val() == null || engraveMessage.val() == '');

                if (engraveMessageValueEmpty) {
                    optionError = true;
                    productOption.find('.invalid-feedback').css('display', 'block');
                    productOption.find('.form-control').addClass('is-invalid');

                    if (firstErroredOption == null) {
                        firstErroredOption = productOption;
                    }
                };
            }

            if (productOption.data('option-id') == 'ringSizeProductOption') {
                var selectedOption = productOption.find('option[selected]');
                var selectedOptionValueEmpty = selectedOption.data('value-id').length === 0;

                if (selectedOptionValueEmpty) {
                    optionError = true;
                    productOption.find('.invalid-feedback').css('display', 'block');
                    productOption.find('.form-control').addClass('is-invalid');

                    if (firstErroredOption == null) {
                        firstErroredOption = productOption;
                    }
                };
            }
        });

        if (optionError == true) {
            if (firstErroredOption) {
                var mobileSearch = $('.search-mobile-row').first();

                if (mobileSearch && mobileSearch.css('display') != 'none') {
                    //Get the parent section of the errored option to scroll to
                    scrollAnimate(firstErroredOption.parent(), mobileSearch.height());
                }
            }
        }

        if (form && optionError != true) {
            form.selectedOptionValueIds = require('core/product/base').methods.getOptions($('#quickViewModal'));
            form.pid = module.exports.getPidValue($(this))
            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('#quickViewModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        module.exports.updateCartTotals(data.cartModel);
                        module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        module.exports.updateAvailability(data.cartModel, form.uuid);
                        module.exports.updateProductDetails(data, form.uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        module.exports.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, form.uuid]);

                        $('body').trigger('tooltip:init');

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            module.exports.createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            }
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#from').attr('value', from);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });

    // check out rvwcore cart -> init - there is no modular way to affect the post delete actions. just use the cart:update event to update empty cart to match on page load look
    $('body').on('cart:update', () => {
        const productCount = $('.cart-page').find('.card-body.product-info').length;
        if (productCount === 0) {
            $('.cart-header').remove();
            $('.cart').find('h1').closest('.row').addClass('cart-header');
            $('.cart-empty').removeClass('d-none');
        }
        showAndHideIFrameCover();
        showAndHideAfterPay();
    });

    // check if preowned acknowledgement has been checked and if not then don't continue and display messaging
    $('body').on('click', '.cart-page .checkout-btn', function (e) {
        if ($('.preowned-confirmation').length != $('.preowned-confirmation:checked').length) {
            e.preventDefault();
            $('.preowned-confirmation:checked').closest('.checkbox-holder').find('.invalid-feedback').hide();
            $('.preowned-confirmation:not(:checked)').closest('.checkbox-holder').find('.invalid-feedback').show();
        }
    });

    $(document).ready(function () {
        if ($('.bonus-product-button:hidden').length > 0 && $('.card-bonus-product-section').length === 0) {
            $('.bonus-product-button:hidden').click();
        } else if ($('.js-embeded-bonus-line-item-container').find('.js-embeded-bonus-line-item').length > 0) {
            setTimeout(function () {
                $(".bonus-product-line-item")[0].scrollIntoView();
            }, 2000);
            $('.paypal-cover-div').hide();
            $('.apple-pay-cover-div').hide();
        }
    });

    /**
     * Function: showAndHideIFrameCover
     * Description: Toggles the visibility of an element with the class 
     * 'after-pay-widged' based on whether a pre-sale product is present. 
     * If a pre-sale product is not present, the element is shown; otherwise, it is hidden.
     */
    function showAndHideAfterPay() {
        let isPreSaleElements = $('.acknowledge-is-pre-sale-product-container');
        let afterPayElement = $(".after-pay-widged");
        if (isPreSaleElements.length === 0) {
            afterPayElement.show();
        } else {
            afterPayElement.hide();
        }
    }

    /**
     * Function: showAndHideIFrameCover
     * Description: Toggles the visibility of an element with the class 
     * 'paypal-cover-div' based on whether a bonus product is not selected. 
     * If a bonus product is not selected, the element is shown; otherwise, it is hidden.
     *
     * Function: checkIfBonusProductNotSelected
     * Description: Checks if a bonus product is not selected by examining the visibility of elements with
     * the class 'js-choose-bonus-product-cart' and the state of the 'without-bonus' checkbox. 
     * Returns true if a bonus product is not selected; otherwise, returns false.
     */
    function showAndHideIFrameCover() {
        $(window).resize(updateIFrameCoverSize);
        $(window).resize();

        updateIFrameCoverSize();

        let $paypalElement = $('.paypal-cover-div');
        let $applepayElement = $('.apple-pay-cover-div');
        if (
            checkIfBonusProductNotSelected() ||
            isPreSaleProductCheckboxNotSelected() ||
            isPreOwnedProductCheckboxNotSelected() ||
            isNoReturnProductCheckboxNotSelected()
        ) {
            $paypalElement.show();
            $applepayElement.show();
        } else {
            $paypalElement.hide();
            $applepayElement.hide();
        }
    }

    $('body').on('click', '.acknowledge-container', function (e) {
        showAndHideIFrameCover();
    });

    /**
     * Function: isPreSaleProductCheckboxNotSelected
     * Description: Checks if a Pre-Sale product acknowledge checkbox is not checked 
     * Returns true if Pre-Sale product acknowledge checkbox is not checked; otherwise, returns false.
     */
    function isPreSaleProductCheckboxNotSelected() {
        let isNotChecked = false;
        $('.acknowledge-is-pre-sale-product-container').each(function () {
            if ($(this).find('.acknowledge-is-pre-sale-product-input').is(':not(:checked)')) {
                isNotChecked = true;
            }
        });
        return isNotChecked;
    }

/**
 * Function: isPreOwnedProductCheckboxNotSelected
 * Description: Checks if a PreOwned product acknowledge checkbox is not checked 
 * Returns true if PreOwned product acknowledge checkbox is not checked; otherwise, returns false.
 */
    function isPreOwnedProductCheckboxNotSelected() {
        let isNotChecked = false;
        $('.acknowledge-is-pre-owned-product-container').each(function () {
            if ($(this).find('.acknowledge-is-pre-owned-product-input').is(':not(:checked)')) {
                isNotChecked = true;
            }
        });
        return isNotChecked;
    }

/**
 * Function: isNoReturnProductCheckboxNotSelected
 * Description: Checks if a No-Return product acknowledge checkbox is not checked 
 * Returns true if No-Return product acknowledge checkbox is not checked; otherwise, returns false.
 */
    function isNoReturnProductCheckboxNotSelected() {
        let isNotChecked = false;
        $('.acknowledge-no-return-product-container').each(function () {
            if ($(this).find('.acknowledge-no-return-product-input').is(':not(:checked)')) {
                isNotChecked = true;
            }
        });
        return isNotChecked;
    }

    /**
     * Function: checkIfBonusProductNotSelected
     * Description: Checks if a bonus product is not selected by examining the visibility of elements 
     * with the class 'js-choose-bonus-product-cart' and the state of the 'without-bonus' checkbox. 
     * Returns true if a bonus product is not selected; otherwise, returns false.
     */
    function checkIfBonusProductNotSelected() {
        if (!$('.js-choose-bonus-product-cart:visible').length && !$('#without-bonus').is(":checked")) {
            return false;
        }
        return true;
    }

    $(document).ready(function () {
        showAndHideIFrameCover();
    });

    // Handle basket Pre-Sale and Pre-Owned product alert/checkboxes
    $('body').on('click', '.paypal-cover-div, .js-cart-checkout-btn, .apple-pay-cover-div', function (e) {
        var scrollTo;
        $('.acknowledge-container').each(function(){

            var $errorMsg = $(this).find('.error-message-for-acknowledge');
            if ($(this).find('.acknowledge-product-input').is(':not(:checked)')) {
                    if (!scrollTo){
                        scrollTo = $(this);
                    }

                    // Prevent default click behavior and stop event propagation
                    e.preventDefault();
                    e.stopPropagation();

                    // Show the cart error message
                    $errorMsg.show();

                    showAndHideIFrameCover();
             
            } else{
                // Hide the cart error message
                $errorMsg.hide();
            }
        });

        if (checkIfBonusProductNotSelected()) {
            scrollAnimate('.js-choose-bonus-product-cart')
        }
        else if (scrollTo && scrollTo.length >= 1) {
            scrollAnimate(scrollTo.closest('.card-body'));
        } 
    });

    $('body').on('ApplePay:Loaded', function (e, response) {

        if (window.dw && window.dw.applepay && window.ApplePaySession && window.ApplePaySession.canMakePayments()) {

            let myInterval = setInterval(function () {
                let $parentElementApplePay = $('.js-paymentmethodwarning-affectedcontainer:has(.dw-apple-pay-button):visible');
                if ($parentElementApplePay.length) {

                    // Apple
                    let parentWidthApplePay = $parentElementApplePay.outerWidth();
                    let parentHeightApplePay = $parentElementApplePay.outerHeight();

                    // Create a new element (div) with the class 'paypal-cover-div' and set its dimensions and styling
                    let applePayCoverDiv = $('<div class="apple-pay-cover-div"></div>').css({
                        'width': parentWidthApplePay + 'px',
                        'height': parentHeightApplePay + 'px',
                        'z-index': 150,
                        'cursor': 'pointer',
                        'position': 'absolute',
                        'min-height': '45px',
                        'background-color': 'rgb(0 0 0 / 0%)'
                    });

                    // Insert the new element into the parent element at the beginning (as the first child)
                    $($parentElementApplePay).prepend(applePayCoverDiv);
                    showAndHideIFrameCover();
                    clearInterval(myInterval)
                }
                100
            });

            setTimeout(function () {
                clearInterval(myInterval)
            }, 1000);

        }

    });
}

module.exports = core;
