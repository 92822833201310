'use strict';

var scrollAnimate = require('core/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(success, msg) {
    var status = success ? 'alert-success' : 'alert-danger';

    if ($('.email-signup-message').length === 0) {
        $('body').append('<div class="email-signup-message"></div>');
    }

    $('.email-signup-message').append('<div class="email-signup-alert text-center alert ' + status + '">' + msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
    }, 3000);
}

var footer = {
    'elementNameMap': {
        'hpEmailSignUp': 'emailId'
    },
    'backToTop': function () {
        $('.back-to-top').click(function () {
            scrollAnimate();
        });
    },
    'copyrightYearUpdate': function () {
        $(window).on('load', function () {
            var date = new Date();
            var year = date.getFullYear();
            $('.js-update-year').html(year);
        });
    },
    'forms': 'footer .email-signup-form form',
    'init': function () {
        var nameMap = this.elementNameMap;

        // Prevent Multi Form event collision
        var $forms = $(this.forms);
        $forms.each(function () {
            var $form = $(this);
            var $inputs = $form.find(':input:not(:disabled)');

            //TODO: Security Update with CSRF

            // Update Form Name for SFRA compatibility
            if (nameMap) {
                $inputs.each(function () {
                    var $input = $(this);
                    var oldName = $input.attr('name');
                    var newName = oldName && nameMap[oldName];

                    if (newName) {
                        $input.attr('name', newName);
                    }
                });
            }

            $form.on('submit', function (e, urlOverride) {
                e.preventDefault();

                var $btn = $(this);
                var xhr = $form.data('xhr');

                $form.data('xhr', $.ajax({
                    url: urlOverride || $form.attr('action'),
                    type: $form.attr('method') || 'post',
                    data: $inputs.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        // Drop duplicate requests
                        xhr && xhr.abort && xhr.abort();

                        $.spinner().start();

                        $btn.attr('disabled', true);
                    },
                    success: function (data) {
                        displayMessage(data.success, data.msg);
                        $form.trigger('footer:signup:success', data);
                    },
                    error: function ($xhr, err, other) {
                        var msg = $xhr.responseJSON && $xhr.responseJSON.message;
                        displayMessage(false, msg);
                    },
                    complete: function () {
                        $.spinner().stop();
                        $btn.removeAttr('disabled');
                        $form[0].reset();
                    }
                }));
            })

            // For SFRA compatibility
            $form.find('.subscribe-email').on('click', function (e) {
                e.preventDefault();
                $form.trigger('submit', $(this).data('href'));
            });
        });
    },
    'marginAdjustment': function() {
        $( window ).on( "resize", function() {
            if ($(window).width() < 769) {
                var $stickyAddToCart = $('.add-to-cart-container');
                var $stickyCheckoutContinue = $('.checkout-continue');
                var $nextStepCO = $('.next-step-button');

                var stickyBtnHeight = 0;
                if ($stickyCheckoutContinue.length > 0) {
                    stickyBtnHeight = $stickyCheckoutContinue[0].clientHeight;
                } else if ($stickyAddToCart.length > 0) {
                    stickyBtnHeight = $stickyAddToCart[0].clientHeight;
                } else if ($nextStepCO.length > 0) {
                    stickyBtnHeight = $nextStepCO[0].clientHeight;
                }

                $('footer').css('margin-bottom', (stickyBtnHeight));
            } else {
                $('footer').css('margin-bottom', '0');
            }
    })
    }
};

module.exports = footer;