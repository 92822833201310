'use strict';

var cookies = require('../components/cookies');

/**
 * Triggers Rolex Tracking via Adobe Analytics
 * @param {String} eventID - The event ID
*/
function triggerTracking(eventID) {
    try {
        _satellite.track(eventID);
    } catch (e) {
        console.log(e);
    }
}

function trackEvent(target) {
    var trackingID = $(target).attr('data-rolex-event-id');
    var rlxConsentCookieValue = cookies.methods.getCookie('rlx-consent');

    if (trackingID && rlxConsentCookieValue === 'true') {
        triggerTracking(trackingID);
    }
}

/**
 * Initiates tracking events
 * @param {String} eventID - The event ID
*/
function initTrackingEvents() {
    $('.js-rolex-tracking').on('click', function () {
        trackEvent(this);
    });

    // Reinit tracking on google map page
    $('.js-rolex-store-locator-section').on('click', function (event) {
        if ($('.js-rolex-store-locator-button[data-section="map"]').hasClass('js-active') && ($(event.target).hasClass('js-rolex-tracking') || $(event.target).parent().hasClass('js-rolex-tracking'))) {
            trackEvent($(event.target).hasClass('js-rolex-tracking') ? event.target : $(event.target).parent()[0]);
        }
    });

    // Observe the body for the onetrust-consent-sdk id being added to the DOM
    function callback (mutationList, observer) {
        if ($('#onetrust-consent-sdk').length > 0 && window._satellite){
            setOneTrustEventHandlers();
            observer.disconnect();
        }
    };

    var rlxConsentCookieValue = cookies.methods.getCookie('rlx-consent');

    if (rlxConsentCookieValue !== 'true') {
        const observer = new MutationObserver(callback);
        observer.observe(document.body, { attributes: true, childList: true, subtree: true });
    }

    function setOneTrustEventHandlers() {
        // Initially set to false
        _satellite.setVar('Analyticsconsent', 'false');

        // Rolex accept/decline buttons for rlx-consent cookie
        $('#onetrust-accept-btn-handler').on('click', function () {
            checkRolexCookie();
        });

        $('#accept-recommended-btn-handler').on('click', function () {
            checkRolexCookie();
        });

        $('.save-preference-btn-container > .save-preference-btn-handler ').on('click', function () {
            checkRolexCookie();
        });
    }
}

function checkRolexCookie() {
    var rlxConsentCookieValue = cookies.methods.getCookie('rlx-consent');

    if (rlxConsentCookieValue === 'true') {
        _satellite.setVar('Analyticsconsent', 'true');
        _satellite.track('PageView');

        // Dynamically add digitialDataLayer script to the head from customHtmlHead.isml
        var dataRolexTrackingPageType = $('.data-rolex-tracking-page-type').attr('data-rolex-tracking-page-type');
        var digitialDataLayerScript = document.createElement('script');
        digitialDataLayerScript.type = 'text/javascript';
        digitialDataLayerScript.innerHTML = `
            var digitalDataLayer = {
                environment: {
                    environmentVersion: 'V7', // static value to be set once Retailer has implemented V7 Design
                    coBrandedVersion: 'Bespoke', // static value to be set by the Retailer
                },
                page: {
                    pageType: '${dataRolexTrackingPageType}' // static value depending on the page name
                }
            };`;
        document.head.appendChild(digitialDataLayerScript);

        // Dynamically add _satellite.pageButtom script to the body from layout/page.isml
        var satellitePageBottomScript = document.createElement('script');
        satellitePageBottomScript.type = 'text/javascript';
        satellitePageBottomScript.innerHTML = `
            if (typeof _satellite !== 'undefined') {
                _satellite.pageBottom();
            }`;
        document.body.appendChild(satellitePageBottomScript);
    } else {
        _satellite.setVar('Analyticsconsent', 'false');
    }
}

$( document ).ready(function() {
    var $rolexPageTypeElement = $(".script-rolex-page-type");

    if ($rolexPageTypeElement && $rolexPageTypeElement.length > 0) {
        var rolexPageType = $rolexPageTypeElement[0].dataset.rolexPageType;
        $(".data-rolex-tracking-page-type")[0].dataset.rolexTrackingPageType = rolexPageType;
    }
});

module.exports = {
    initTrackingEvents: initTrackingEvents,
    methods: {
        triggerTracking: triggerTracking,
        trackEvent: trackEvent
    }
};
